.projetos-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  justify-content: center;
}

.projeto-card {
  width: 100%;
  max-width: 300px;
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  border: 1px solid #e1e1e1;
  margin-top: 60px;
}

.projeto-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.projeto-imagem {
  width: 100%;
  height: 180px;
  object-fit: cover;
  display: block;
}

.projeto-info {
  padding: 10px;
  text-align: center;
}

.projeto-titulo {
  color: #001326; 
  margin-bottom: 5px;
  font-size: 16px; 
}

@media only screen 
and (max-device-width: 1000px)
{.projetos-container {
  margin-bottom: 30%;
} }

.progresso-barra-container {
  background-color: #f1f1f1; 
  border-radius: 8px;
  margin-top: 10px;
  position: relative;
  overflow: hidden;
  height: 20px;
}

.progresso-barra {
  height: 100%;
  transition: width 0.4s ease;
}

.progresso-info {
  position: absolute;
  right: 10px;
  top: 1px;
  font-size: 12px;
  color: #333;
}

.filtro-btn, .filtros-container {
  position: fixed;
  z-index: 1000;
}

.filtro-btn {
  top: 100px;  
  position: fixed;
  right: 20px;
  padding: 10px 20px;
  background-color: #ffc85c;
  color: #001326;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: background-color 0.3s ease;
  z-index: 1001;
}

.filtros-container {
  top: 60px;  
  display: none;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  z-index: 999;
}

@media only screen 
and (max-device-width: 1000px) {
  .filtros-container {
    left: 20px;
  }
}

.filtros-container.active {
  display: block;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.filtro-select {
  width: 100%;
  padding: 10px 15px;
  border-radius: 8px;
  border: 2px solid #001326;
  background-color: #fff;
  color: #001326;
  font-size: 16px;
  cursor: pointer;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="%23001326"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
  transition: border-color 0.3s ease-in-out;
}

.filtro-select:hover, .filtro-select:focus {
  border-color: #ffc85c;
}

.filtro-select:focus {
  outline: none;
  box-shadow: 0 0 0 2px #ffc85c;
} 
