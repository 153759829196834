.reset-password-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
   
  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 8px;
    color: #333;
    font-weight: bold;
  }
  
  .input-wrapper {
    position: relative;
  }
  
  .form-input {
    width: 100%;
    padding: 12px;
    padding-right: 40px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .form-input:focus {
    border-color: #ffc85c;
  }
  
  .password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #666;
  }
  
  .password-criteria {
    font-size: 12px;
    color: #999;
  }
  
  .submit-button {
    width: 100%;
    padding: 12px;
    background-color: #ffc85c;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .submit-button:hover {
    background-color: #ffb347;
  }
  
  @media (max-width: 600px) {
    .reset-password-container {
      width: 90%;
      padding: 15px;
    }
  
    .form-input {
      padding: 10px;
    }
  
    .submit-button {
      padding: 10px;
    }
  }
  