.custom-navbar {
    background-color: #001326 !important;
    z-index: 1002;
  }
   
  .custom-navbar .navbar-brand,
  .custom-navbar .nav-link {
    color: #e7e7e6 !important;
  }
  
  .custom-navbar .login-link {
    background-color: #e7e7e6;
    color: #001326 !important;
    border-radius: 5px;
    margin-left: 10px;
    display: flex;
    align-items: center;
  }
  
  .custom-navbar .login-link:hover {
    background-color: #d4d4d4;
    color: #001326 !important;
  }
  
  .user-icon {
    margin-right: 5px;
  }
  
  .custom-navbar .navbar-toggler-icon {
    background-image: none;
    position: relative;
    width: 30px;
    height: 24px; 
  }
  
  .custom-navbar .navbar-toggler-icon::before,
  .custom-navbar .navbar-toggler-icon::after,
  .custom-navbar .navbar-toggler-icon span {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #e7e7e6;
  }
  .custom-navbar .dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between; 
  }
  
  .custom-navbar .dropdown-toggle::after {
    margin-left: 10px; 
      color: #001326; 
  }
  
  .custom-navbar .login-link {
    gap: 10px; 
  }
  .custom-navbar .navbar-toggler-icon::before {
    top: 0;
  }
  
  .custom-navbar .navbar-toggler-icon::after {
    bottom: 0;
  }
  
  .custom-navbar .navbar-toggler-icon span {
    top: 50%;
    transform: translateY(-50%);
  }
  @media (max-width: 992px) { 
    .custom-navbar .dropdown {
      position: relative;
    }
  
    .custom-navbar .dropdown-menu {
      position: absolute;
      top: 100%; 
      left: 0;
      right: 0; 
      width: 100%; 
      border: none;
      border-radius: 0;
      box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    }
  
    .custom-navbar .dropdown-item {
      display: block;
      width: 100%;
      color: #001326; 
      padding: 10px;
    }
  }