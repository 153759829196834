

.financiar-projeto {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.projeto-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.projeto-imagem {
  max-width: 300px; 
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  margin: 10px 0;
  color: #001326;
}

.doacao-section {
  margin: 20px 0;
}

label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #001326;
}

.donation-input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.donation-input-container span {
  font-size: 1.2em;
  color: #001326;
}

input[type="text"] {
  width: calc(100%);
  padding: 10px;
  margin-bottom: 20px;
  border: 2px solid #ffc85c;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.payment-methods {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.payment-method-button {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  background-color: #ffc85c;
  color: #001326;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.button-container {
  display: flex;
  justify-content: space-between; 
  gap: 20px; 
  margin-top: 20px;
}

.financiar-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 10px 20px; 
  background-color: #ffc85c;
  color: #001326;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.financiar-button:hover {
  background-color: #e6b255;
  transform: scale(1.05);
}

.button-icon {
  font-size: 20px;
}

#donationAmount {
  font-size: 1.5em;
  font-weight: bold;
  color: #555; 
}

#donationAmount::placeholder {
  color: #ccc;
}
.payment-method-button:hover {
  background-color: #e6b255;
}

.payment-logo {
  width: 24px;
  height: auto;
}

.payment-icon {
  font-size: 24px;
}

.payment-info {
  margin: 20px 0;
}

.payment-info h2 {
  color: #001326;
}

.pix-copia-e-cola {
  word-break: break-all;
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.payment-info img {
  max-width: 100%;
  height: auto;
  margin: 20px 0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pagamento-button {
  padding: 10px 20px;
  background-color: #ffc85c;
  color: #001326;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagamento-button:hover {
  background-color: #e6b255;
}

.credit-card-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.credit-card-form label {
  font-weight: bold;
  color: #001326;
}

.credit-card-form input {
  padding: 10px;
  border: 2px solid #ffc85c;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
 

