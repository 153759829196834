.floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    background-color: #001326;
    color: #ffc85c;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    cursor: pointer;
    z-index: 1000;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .floating-button:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  
  @media (max-width: 768px) {
    .floating-button {
      width: 70px;
      height: 70px;
      font-size: 35px;
      bottom: 15px;
      right: 15px;
    }
  }
   
  @media (max-width: 480px) {
    .floating-button {
      width: 80px;
      height: 80px;
      font-size: 40px;
      bottom: 10px;
      right: 10px;
    }
  }