
.meus-pagamentos-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .titulo-pagina {
    text-align: center;
    font-size: 2rem;
    color: #001326;
    margin-bottom: 20px;
  }
  

  .saldos {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  
  .saldo-card {
    flex: 1;
    min-width: 280px;
    margin: 10px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: all 0.3s ease;
  }
  
  .saldo-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .saldo-total {
    border-left: 4px solid #ffc85c;
  }
  
  .saldo-liberado {
    border-left: 4px solid #4caf50;
  }
  
  .saldo-pendente {
    border-left: 4px solid #f44336;
  }
  
  .saldo-saques {
    border-left: 4px solid #f57c00;
  }
  
  .historico-detalhado, .historico-saques {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .historico-projeto, .historico-item {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s;
  }
  
  .historico-projeto:hover, .historico-item:hover {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  }
  
  .historico-item {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    transition: background-color 0.3s;
  }
  
  .historico-item:hover {
    background-color: #e6e6e6;
  }
  
  .valor-entrada {
    color: #4caf50;
  }
  
  .valor-saque {
    color: #f44336;
  }
  
  @media (max-width: 768px) {
    .saldos {
      flex-direction: column;
    }
  
    .saldo-card {
      min-width: 100%;
    }
  }
  
  .solicitar-saque-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    margin-top: 10px;
    transition: background-color 0.3s ease;
    margin-bottom: 20px;
    width: 100%; 
    box-sizing: border-box; 
  }
  
  .solicitar-saque-button:hover {
    background-color: #45a049;
  }
  
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999; 
  }
  
  .modal-saque {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    width: 400px;
    max-width: 90%;
    text-align: center;
    z-index: 99991000; 
  }
  
  .modal h3 {
    margin-bottom: 20px;
  }
  
  .modal label {
    display: block;
    text-align: left;
    margin: 10px 0 5px;
  }
  
  .modal input,
  .modal select {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-between;
  }
  
  .modal-button {
    background-color: #001326;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .modal-button.cancel {
    background-color: #f44336;
  }
  
  .modal-button:hover {
    background-color: #000;
  }
  
  .modal-button.cancel:hover {
    background-color: #d32f2f;
  }