@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #d4d4d4;
  color: #001326;
}

.conta-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh; 
  max-height: 100vh;
  padding: 20px;
}

.conta-card { 
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
  max-width: 800px;
  width: 100%;
  transition: transform 0.2s;
}

.conta-card h2 {
  margin-bottom: 20px;
  font-size: 28px;
  text-align: center;
  border-bottom: 2px solid #ffc85c;
  padding-bottom: 10px;
}

.conta-info {
  display: flex;
  flex-direction: column;
}

.conta-info-row {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.conta-info-row p {
  margin: 0;
  font-size: 18px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.conta-info-row p strong {
  color: #001326;
  margin-right: 10px;
}

.logout-button {
  width: 100%;
  max-width: 700px;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  color: #ffffff;
  background-color: #001326;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.6s;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.logout-button:hover {
  background-color: #ffc85c;
  color: #001326;
}

@media (max-width: 768px) {
  .conta-info-row {
    flex-direction: column;
  }

  .conta-info-row p {
    margin: 5px 0;
  }
}
