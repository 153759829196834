.participacoes-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .participacoes-title {
    text-align: center;
    color: #001326;
  }
  
  .participacoes-list {
    list-style-type: none;
    padding: 0;
  }
  
  .participacoes-item {
    background-color: #ffc85c;
    margin: 10px 0;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    display: flex;
    align-items: center;
  }
  
  .participacoes-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
  
  .participacoes-link {
    text-decoration: none;
    color: #001326;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    align-items: center;
  }
  
  .participacoes-link:hover {
    color: #000;
  }
  
  .participacoes-image {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    margin-right: 15px;
  }
   
  .participacoes-name {
    flex: 1;
  }