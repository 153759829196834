.project-details-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .project-title {
    text-align: center;
    color: #001326;
    background-color: #ffc85c;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }
  
  .messages-container {
    margin-bottom: 20px;
    max-height: 400px;
    overflow-y: auto;
  }
  
  .message-item {
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
  }
  
  .my-message {
    background-color: #001326;
    color: #ffc85c;
    align-self: flex-end;
    text-align: right;
  }
  
  .other-message {
    background-color: #f0f0f0;
    color: #001326;
    align-self: flex-start;
  }
  
  .message-username {
    font-weight: bold;
    margin-right: 5px;
  }
  
  .send-message-container {
    display: flex;
    flex-direction: column;
  }
  
  .message-input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
  }
  
  .send-message-button {
    padding: 10px;
    background-color: #001326;
    color: #ffc85c;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .send-message-button:hover {
    background-color: #002d4d;
  }
  
  .load-more-button {
    padding: 10px;
    background-color: #ffc85c;
    color: #001326;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
    transition: background-color 0.3s;
  }
  
  .load-more-button:hover {
    background-color: #ffd280;
  }
   