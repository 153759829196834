.blog-card {
  display: flex;
  flex-direction: column;
}

.blog-card img {
  border-radius: 8px 8px 0 0;
  height: 150px; 
  object-fit: cover;
}

.blog-card div {
  padding: 10px;
}

.custom-arrow {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: #fff;
  padding: 10px;
  border-radius: 10%;
  cursor: pointer;
}

.custom-arrow:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.left-arrow {
  left: 32px;
}

.right-arrow {
  right: 32px;
}

.custom-dots button {
  background-color: #ccc;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin: 0 5px;
}

.custom-dots button.active {
  background-color: #001326;
}


.carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.slick-track {
  display: flex;
  align-items: center;
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-slide img {
  width: 100vw; 
  height: 50vh; 
  object-fit: cover; 
}
@media (max-width: 992px) { 
  .carousel-slide img {
    width: 100vw; 
    height: 20vh; 
    object-fit: cover; 
  }

}