.pagamentos-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.pagamentos-title {
    text-align: center;
    color: #001326;
}
 
.pagamentos-list {
    list-style-type: none;
    padding: 0;
}

.pagamentos-item {
    background-color: #ffc85c;
    margin: 10px 0;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    display: flex;
    align-items: center;
}

.pagamentos-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.pagamentos-link {
    text-decoration: none;
    color: #001326;
    display: flex;
    align-items: center;
    width: 100%;
}

.pagamentos-image {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    margin-right: 15px;
}

.pagamentos-info {
    display: flex;
    flex-direction: column;
}

.pagamentos-project-name {
    font-weight: bold;
    font-size: 18px;
}

.pagamentos-amount {
    font-size: 16px;
    color: #666;
}
