body {
    background-color: #001326;
    color: #ffffff;
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .meus-projetos-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  h2 {
    color: #ffc85c;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .projetos-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
  }
  
  .projetos-table th,
  .projetos-table td {
    padding: 10px;
    text-align: left;
  }
  
  .projetos-table th {
    background-color: #ffc85c;
    color: #001326;
    font-size: 16px;
    font-weight: bold;
  }
  
  .projetos-table tr {
    background-color: #ffffff;
    transition: transform 0.3s ease, background-color 0.3s ease;
  }
  
  .projetos-table tr:hover {
    background-color: #f0f0f0;
    transform: translateY(-3px);
    cursor: pointer;
  }
  
  .projeto-imagem {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .projeto-imagem:hover {
    transform: scale(1.05);
  }
  
  .projetos-table td {
    border-bottom: 1px solid #ddd;
  }
  
  .projetos-table td:last-child {
    border-right: none;
  }
  
  .projetos-table th:first-child,
  .projetos-table td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  
  .projetos-table th:last-child,
  .projetos-table td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .table-container {
    padding: 10px;
    overflow-x: auto;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.9em;
  }
  
  .table th, .table td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }
  
  .table th {
    background-color: #ffc85c;
    color: #001326;
  }
  
  .table td {
    color: #001326;
  }
  
  .table img {
    max-width: 100px;
    height: auto;
  }
  
  .table-row {
    cursor: pointer;
    border-bottom: 1px solid #ddd;
  }
  
  .table-row:hover {
    background-color: #f5f5f5;
  }
  
  @media (max-width: 768px) {
    .table th, .table td {
      padding: 8px;
    }
  
    .table img {
      max-width: 80px;
    }
  }
  .faq-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 1em;
    min-width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .faq-table thead {
    background-color: #001326;
    color: #ffc85c;
  }
  
  .faq-table th, 
  .faq-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .faq-table th {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.9em;
  }
  
  .faq-table tbody tr {
    border-bottom: 1px solid #ddd;
  }
  
  .faq-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
  
  .faq-table tbody tr:last-of-type {
    border-bottom: 2px solid #001326;
  }
  
  .faq-table button {
    padding: 8px 16px;
    background-color: #ffc85c;
    color: #001326;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
    transition: background-color 0.3s ease;
  }
  
  .faq-table button:hover {
    background-color: #ffb53a;
  }
  
  @media (max-width: 768px) {
    .faq-table {
      font-size: 0.85em;
    }
    
    .faq-table th, 
    .faq-table td {
      padding: 10px;
    }
  }