.container-cadastro {
    background-color: #ffffff;
    color: #001326;
    padding: 40px;
    border-radius: 12px;
    max-width: 500px;
    width: 100%;
    margin: 50px auto;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s ease-in-out;
    box-sizing: border-box; 
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}
.terms-link {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer; 
    color: #001326; 
  }
  
  .terms-link:hover {
    text-decoration: underline;
    color: #FFC85C; 
  }
h2 {
    text-align: center;
    margin-bottom: 30px;
}

.form-step, .login-step {
    margin-bottom: 20px;
}

.form-label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

.form-input {
    width: 100%;
    padding: 12px;
    border-radius: 6px;
    border: 1px solid #001326;
    margin-bottom: 20px;
    transition: border-color 0.3s;
}

.form-input:focus {
    border-color: #ffdc8f;
    outline: none;
}

.button-group {
    display: flex;
    justify-content: space-between;
}

.button {
    padding: 12px 20px;
    border-radius: 6px;
    border: none;
    background-color: #ffdc8f;
    color: #001326;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    flex: 1;
}

.button:hover {
    background-color: #ffc85c;
    transform: scale(1.05);
}

.button:active {
    transform: scale(0.95);
}

.button.prev {
    margin-right: 10px;
}

.form-complete {
    text-align: center;
}

.link {
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
    color: #001326;
    text-decoration: underline;
}

.input-wrapper {
    position: relative;
}

.password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}

.password-instructions {
    font-size: 0.9em;
    margin-bottom: 10px;
    color: #555;
}

@media (max-width: 768px) {
    .container-cadastro {
        padding: 20px;
        margin: 20px;
        width: calc(100% - 40px); 
    }

    .button {
        padding: 10px 16px;
    }

    h2 {
        margin-bottom: 20px;
    }

    .form-input {
        padding: 10px;
        margin-bottom: 15px;
    }
}
