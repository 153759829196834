.container-cadastro {
    background-color: #ffffff;
    color: #001326;
    padding: 40px;
    border-radius: 12px;
    max-width: 800px;
    width: 100%;
    margin: 50px auto;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s ease-in-out;
    box-sizing: border-box;
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

h2 {
    text-align: center;
    margin-bottom: 30px;
}

.form-step {
    margin-bottom: 20px;
}

.form-label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

.social-fields {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .social-field {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  .meta-arrecadacao-desconto {
    background-color: #f8f9fa;
    border: 2px solid #ffc85c;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    color: #001326;
    font-size: 1.1rem;
    font-weight: bold;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
  }
  
  .meta-arrecadacao-desconto span {
    display: block;
    font-size: 1rem;
    font-weight: normal;
    color: #6c757d;
    margin-bottom: 5px;
  }
  
  .meta-input {
    font-size: 1.5rem;
    font-weight: bold;
    color: #001326;
  }
  .social-field input {
    flex: 1;
    border: none;
    outline: none;
    padding: 10px;
    background-color: transparent;
    font-size: 1rem;
  }

.form-input, .form-textarea, .form-select {
    width: 100%;
    padding: 12px;
    border-radius: 6px;
    border: 1px solid #001326;
    margin-bottom: 20px;
    transition: border-color 0.3s;
}

.form-input:focus, .form-textarea:focus, .form-select:focus {
    border-color: #ffdc8f;
    outline: none;
}

.button-group {
    display: flex;
    justify-content: space-between;
}

.button {
    padding: 12px 20px;
    border-radius: 6px;
    border: none;
    background-color: #ffdc8f;
    color: #001326;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    flex: 1;
}

.button:hover {
    background-color: #ffc85c;
    transform: scale(1.05);
}

.button:active {
    transform: scale(0.95);
}

.button.prev {
    margin-right: 10px;
}

.ods-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.meta-arrecadacao {
    display: flex;
    align-items: center;
}
.dropzone {
    border: 2px dashed #cccccc;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
  }
  
  .image-preview {
    max-width: 100%;
    max-height: 200px;
    margin-top: 10px;
  }
  
.button-meta {
    padding: 12px 20px;
    border-radius: 6px;
    border: none;
    background-color: #ffdc8f;
    color: #001326;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    flex: none;
    margin: 0 10px;
}

.button-meta:hover {
    background-color: #ffc85c;
    transform: scale(1.05);
}

.button-meta:active {
    transform: scale(0.95);
}

.meta-input {
    flex: 1;
    text-align: center;
}

.ods-item {
    flex: 1 1 calc(33.333% - 10px);
    box-sizing: border-box;
    padding: 20px;
    margin: 5px;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    background-color: #ffffff;
    color: #001326;
    transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s, color 0.3s;
}

.ods-item:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.ods-item.selected {
    background-color: #001326;
    color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    border: 2px solid #ffdc8f;
}

.ods-item h3 {
    margin: 10px 0;
    font-size: 1.2em;
}

.ods-item p {
    font-size: 0.9em;
    margin: 5px 0 0 0;
}

.ods-item i {
    font-size: 3em;
    margin-bottom: 10px;
}

.form-complete {
    text-align: center;
}

.link {
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
    color: #001326;
    text-decoration: underline;
}

@media (max-width: 768px) {
    .container-cadastro {
        padding: 20px;
        margin: 20px;
        width: calc(100% - 40px); 
    }

    .button {
        padding: 10px 16px;
    }

    h2 {
        margin-bottom: 20px;
    }

    .form-input, .form-textarea, .form-select {
        padding: 10px;
        margin-bottom: 15px;
    }

    .ods-item {
        flex: 1 1 calc(50% - 10px);
    }
}

@media (max-width: 480px) {
    .ods-item {
        flex: 1 1 100%;
    }
}
