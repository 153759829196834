.typing-indicator {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
  }
  
  .dot {
    width: 8px;
    height: 8px;
    background-color: #001326;
    border-radius: 50%;
    margin: 0 4px;
    animation: bounce 1s infinite ease-in-out;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-8px);
    }
  }
  .material-icons-outlined {
    opacity: 0; 
    transition: opacity 0.2s ease-in-out;
  }
  
  .font-loaded .material-icons-outlined {
    opacity: 1; 
  } 