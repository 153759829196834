.dashboard-content {
    display: flex;
    flex-wrap: wrap;
  }
  
  .chart-container {
    flex: 1;
    min-width: 300px;
    margin-right: 20px;
  }
  
  .table-container {
    flex: 1;
    min-width: 300px;
    margin-top: 20px;
  }
  
  .access-log-table, .transaction-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .access-log-table th, .transaction-table th {
    background-color: #f5f5f5;
    padding: 10px;
    text-align: left;
  }
  
  .access-log-table td, .transaction-table td {
    padding: 8px;
    border: 1px solid #ddd;
  }
  .export-buttons {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .export-button {
    background-color: #ffc85c;
    border: none;
    color: #001326;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .export-buttons {
      justify-content: center;
    }
  
    .export-button {
      width: 100%;
      margin-bottom: 10px;
    }
  } 